/**
 * Initiate priorityNav
 * -------------------------------------------------- */

  function prioritize() {
    $('.js-priority-nav').flexMenu({
      showOnHover: false,
      popupClass: 'c-nav__dropdown'
    });
  }
  
  function unPrioritize() {
    $('.js-priority-nav').flexMenu({
      undo: true
    });
  }
  
  // $(document).ready(function() {
  var windowWidth = $(window).width();
  var resizeTimeout;
  
  prioritize();
  
  if (windowWidth < 768) {
    unPrioritize();
  } else {
    prioritize();
  }
  
  // https://stackoverflow.com/questions/24460808/efficient-way-of-using-window-resize-or-other-method-to-fire-jquery-functions
  $(window).resize(function() {
    if (!!resizeTimeout) {
      clearTimeout(resizeTimeout);
    }
    resizeTimeout = setTimeout(function() {
      var windowResizedWidth = $(window).width();
      if (windowResizedWidth < 960) {
        unPrioritize();
      } else {
        prioritize();
      }
    }, 100);
  });
  // }); // Document Ready