$(document).ready(function(){
  init_video();
});

/** =============================================================================
Video
--------------
Es können Youtube oder selbst gehostete Videos eingebunden werden.

THUMBNAIL (Bild src als Video Thumbnail)
data-thumb="https://thumb-src"  // Wenn kein thumb angegeben ist, wird bei Youtube Videos das Youtube Thumbnail geladen. Bei selbst gehosteten Videos wird direkt das Video eingebunden.

YOUTUBE
data-type="youtube"
data-embed="Youtube Video ID"   // Bei Youtube Videos nur die Video ID angeben

SELBST GEHOSTET
data-type="self"
data-embed="video_filename"   // Video Dateiname ohne Suffix. Das Suffix (mp4,ogv,webm) wird automatisch generiert
data-format="mp4,ogg,webm"    // Video Formate (mp4,ogg oder webm). Für jedes Format wird ein entsprechendes source Tag aus video_filename + format generiert
============================================================================ */
function init_video ()
{
  $('.m-video__item').each(function(i)
  {
    var $elem     = $(this);
    var type      = $elem.data('type');
    var self      = type !== 'youtube';
    var thumb     = $elem.data('thumb');
    var video     = $elem.data('embed');
    var has_thumb = typeof thumb != 'undefined';
    var thumb_src = ( has_thumb ) ? thumb : ( self ) ? false : "https://img.youtube.com/vi/" + $elem.data('embed') + "/maxresdefault.jpg";
    var video_src = ( self ) ? video : 'https://www.youtube-nocookie.com/embed/' + video + '?rel=0&showinfo=0&autoplay=1';

    if ( self )
    {
      var suffix = {
      // format: suffix
        'mp4':  'mp4',
        'ogg':  'ogv',
        'webm': 'webm'
      };
      var format = $elem.data('format').split(',');
      var embed  = '<video class="u-embed__item" controls autoplay="autoplay" style="z-index:1">';
      for ( var i in format ) embed += '<source src="'+video_src+'.'+suffix[format[i]]+'" type="video/'+format[i]+'">';
      embed += '</video>';
    }
    else
    {
      var embed = $('<iframe class="u-embed__item" frameborder="0" allowfullscreen="" src="'+video_src+'" style="z-index:1">');
    }

    if ( thumb_src !== false )
    {
      var $image = $('<img>').attr('src', thumb_src);
      $elem.append($image);

      var click_func = function ()
      {
        var func = this;
        $elem.append(embed);
        $elem.find('>div,>img').fadeOut('slow');
        $elem.off('click', click_func);
      };

      $elem.on('click', click_func);
    }
    else
    {
      $elem.append(embed);
    }
  });
}